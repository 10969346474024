// =============================================================================
// Helper classes
// =============================================================================
.rot, 
.red {
  color: color('alert');
}

.blau,
.blue {
  color: color(brand-primary);
}

.to-left {
  float: left;
}

.to-right {
  float: right;
}

.text-right {
  text-align: right;
}

.clearfix {
  @include clearfix;
}

.clear {
  clear: both;
}

.nowrap, 
.noWrap { //deprecated
  white-space: nowrap;
}

.pdf {
  background: url('/img/layout/acrobat_24.png') 0 .15em no-repeat;
  display: inline-block;
  float: left;
  padding-bottom: 1em;
  padding-left: 2em;
}

.hidden {
  @include hidden;
}

.visuallyhidden {
  @include visuallyhidden;
}

.visuallyhidden--focusable {
  @include visuallyhidden-focusable;
}

.invisible {
  @include invisible;
}

.ir,
.hide-text {
  @include hide-text;
}

.has-hyphen--ml {
  @include hyphen('mobile-landscape');
}

.has-hyphen--ts {
  @include hyphen('tablet-small');
}

.has-hyphen--t {
  @include hyphen('tablet');
}

.has-hyphen--tl {
  @include hyphen('tablet-landscape');
}

.has-hyphen--c {
  @include hyphen('computer');
}

.has-bg {
  background-color: color('white');
}

.has-bg-brand-primary-10 {
  background-color: color('brand-primary-10');
}

.has-padding {
  padding: 1rem gutter(of $small-columns);
  
  @include breakpoint($tablet-small) {
    padding-left: gutter(of $medium-columns);
    padding-right: gutter(of $medium-columns);
  }

  @include breakpoint($computer) {
    padding-left: gutter(of $large-columns);
    padding-right: gutter(of $large-columns);
  }
}

.has-padding-left {
  padding-left: gutter(of $small-columns);
  
  @include breakpoint($tablet-small) {
    padding-left: gutter(of $medium-columns);
  }

  @include breakpoint($computer) {
    padding-left: gutter(of $large-columns);
  }
}

.has-padding-left-right {
  padding-left: gutter(of $small-columns);
  padding-right: gutter(of $small-columns);
  
  @include breakpoint($tablet-small) {
    padding-left: gutter(of $medium-columns);
    padding-right: gutter(of $medium-columns);
  }

  @include breakpoint($computer) {
    padding-left: gutter(of $large-columns);
    padding-right: gutter(of $large-columns);
  }
}
