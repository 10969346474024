// hero-text
// -----------------------------------------------------------------------------
.c-hero-text {
  @include ff('secondary');
  @include type-scale(t7);

  bottom: 0;
  color: color('white');
  display: block;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 0;
  overflow: visible;
  position: absolute;
  text-align: left;  
  z-index: 100;
  
  &.c-hero-text--sieh-an {
    color: hsla(0, 0%, 100%, .15);
    right: 0;
  }

  &.c-hero-text--suche {
    color: hsla(0, 0%, 100%, .25);
    left: 0;
  }

  &.c-hero-text-finde {
    color: hsla(0, 0%, 100%, .2);
    right: 0;
  }

  &.c-hero-text--starte {
    color: rgba(color('black'), .25);//hsla(0, 0%, 100%, .25);
  }

  @include breakpoint(orientation portrait) {
    &--sieh-an {
      @include type-scale(t6);
      line-height: 0;
    }

    @include breakpoint($mobile-landscape) {
      @include type-scale(t9);
      
      &--sieh-an {
        @include type-scale(t8);
      }
    }
    
    @include breakpoint($tablet) {
      @include type-scale(t10);

      &--sieh-an {
        @include type-scale(t9);
      }
    }

    @include breakpoint($tablet-landscape) {
      @include type-scale(t11);

      &--sieh-an {
        @include type-scale(t10);
      }
    }
  }

  @include breakpoint(orientation landscape) {
    @include type-scale(t9);

    &--sieh-an {
      @include type-scale(t8);
    }

    @include breakpoint($tablet-small) {
      @include type-scale(t10);

      &--sieh-an {
        @include type-scale(t9);
      }
    }

    @include breakpoint($tablet-landscape) {
      @include type-scale(t11);

      &--sieh-an {
        @include type-scale(t10);
      }
    }

    @include breakpoint($computer) {
      @include type-scale(t12);

      &--sieh-an {
        @include type-scale(t11);
      }
    }

    @include breakpoint($computer-large) {
      &--sieh-an {
        @include type-scale(t12);
      }
    }
  }
}
