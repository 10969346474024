// Typography
// ----------
// For all Typography related values. 
// Uses Compass Vertical Rhythms. 
// ==========

// Compass Vertical Rhythms Settings
// ---------------------------------
$base-font-size: 16px;
$base-line-height: 27px;
$round-to-nearest-half-line: true;

// Uses the `rem` unit for all vertical rhythm output
$rhythm-unit: 'rem';

// Font Families
// ------------
$font-families: (
  primary: ('AmbleRegular', 'Helvetica', 'Arial', sans-serif),
  secondary: ('VollkornRegular', 'Helvetica', 'Arial', sans-serif),
  secondary-italic: ('VollkornItalic', 'Helvetica', 'Arial', sans-serif),
  bold: ('AmbleBold', 'Helvetica', 'Arial', sans-serif),
  bold-italic: ('AmbleBoldItalic', 'Helvetica', 'Arial', sans-serif),
  //semibold: ('OpenSansSemibold', 'Helvetica', 'Arial', sans-serif),
  italic: ('AmbleItalic', 'Helvetica', 'Arial', sans-serif),
  light: ('AmbleLight', 'Helvetica', 'Arial', sans-serif),
  light-italic: ('AmbleLightItalic', 'Helvetica', 'Arial', sans-serif),
  code: ('Monaco', 'Geneva', 'Consolas', monospace)
);

// Font Weights
// ------------
$font-weights: (
  primary: (
    light: 300,
    normal: 400,
    bold: 700
  ),
  secondary: (
    light: 300,
    normal: 400,
    bold: 700
  ),
  bold: (
    bold: normal
  )
);

// Font Weights single
// ------------
$font-weight-default: (
  primary: normal,
  secondary: normal,
  bold: normal,
  bold-italic: normal,
  light: normal,
  light-italic: normal
);

// Type Sizes
// ----------
// Sets font sizes and line height. 
// Adapted from https://medium.com/p/8e51498c3713 
// See mixin type-scale for more info
//
// - requires compass vertical rhythm (included by default)
// ==========

// Type Scale
$type-scale: (
  t12: (
    font-size: 504.921px,
    base-lines: 0
  ),
  t11: (
    font-size: 378.785px,
    base-lines: 0
  ),
  t10: (
    font-size: 284.16px,
    base-lines: 0
  ),
  t9: (
    font-size: 213.173px,
    base-lines: 0
  ),
  t8: (
    font-size: 159.5px,
    base-lines: 0
  ),
  t7: (
    font-size: 119.665px,
    base-lines: 5
  ),
  t6: (
    font-size: 89.764px,
    base-lines: 4
  ),
  t5: (//h0
    font-size: 67.34px,
    base-lines: 3
  ),
  t4: (//h1
    font-size: 50.517px,
    base-lines: 2.5
  ),
  t3: (//h2
    font-size: 37.897px,
    base-lines: 1.75
  ),
  t2: (//h3
    font-size: 28.43px,
    base-lines: 1.25
  ),
  t1: (//h4
    font-size: 21.328px,
    base-lines: 1
  ),
  //h5: (
  //  font-size: 12.8px,
  //  base-lines: 0.75
  //),
  //h6: (
  //  font-size: 10.24px,
  //  base-lines: 0.5
  //),
  base: (
    font-size: $base-font-size,
    base-lines: 1
  ),
  s1: (
    font-size: 12.003px,
    base-lines: 1
  ),
  s2: (
    font-size: 9.005px,
    base-lines: 1
  )
);
