// MIXINS
// ------
// General mixins for projects
// ======

// Clearfix
// --------
@mixin clearfix {
  &:after {
    display: table;
    clear: both;
    content: ' ';
  }
}

// Font Smoothing
// --------------
// Mixin to turn on font smoothing, especially for light text on dark background for OSX
@mixin font-smoothing($value: on) {
  @if $value == on {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  } @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}

// hidden
// ------
// Hide visually and from screen readers://
@mixin hidden {
  display: none !important;
}

// visuallyhidden
// --------------
// Hide only visually, but have it available for screen readers:
// http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
@mixin visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

// visuallyhiddenFocusable
// -----------------------
// Extends the .visuallyhidden class to allow the element
// to be focusable when navigated to via the keyboard:
// https://www.drupal.org/node/897638
@mixin visuallyhidden-focusable {
  &:active,
  &:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
  }
}

// invisible
// ---------
// Hide visually and from screen readers, but maintain layout
@mixin invisible {
  visibility: hidden;
}

// Image-replacement
// -----------------
// Hides text for image replacement
// Source: https://github.com/h5bp/html5-boilerplate/commit/aa0396eae757
@mixin hide-text {
  background-color: transparent;
  border: 0;
  color: transparent;
  font: #{0 / 0} a;
  text-shadow: none;
}

// Placeholder
// -----------
// Changes Placeholder styles
@mixin placeholder {
  &::placholder {
    @content;
  }
}

// selection
// ---------
// Remove text-shadow in selection highlight:
// https://twitter.com/miketaylr/status/12228805301
//
// These selection rule sets have to be separate.
// Customize the background color to match your design.
@mixin selection(
  $bg-color: color('selection'), 
  $text-color: color('white')
) {
  &::selection {
    background: $bg-color;
    color: $text-color;
    text-shadow: none;
  }
}

// Trans-prep
// ----------
// Prepares for transforms. Gives backface-visibility: hidden 
// to remove flickering effect on Webkit browsers
// - http://blog.teamtreehouse.com/increase-your-sites-performance-with-hardware-accelerated-css
@mixin trans-prep {
  // backface visibility
  backface-visibility: hidden;
}

// Z-index
// -------
@mixin zi($location) {
  z-index: map-get($zindex, $location);
}

// Emboss
// ------
// http://sachagreif.com/useful-sass-mixins/
// .box{
//   @include box-emboss(0.8, 0.05);
// }
///
@mixin box-emboss($opacity, $opacity2) {
  box-shadow: white($opacity) 0 1px 0, inset black($opacity2) 0 1px 0;
}
