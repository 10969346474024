@mixin section-keyvisual(
  $name: null, 
  $z-index: null, 
  $bgImg: null, 
  $bgPos: 50% 50%
) {
  @if $name != null {
    $color: 'bg-' + $name;
    @if color($color) == null {
      $color: 'bg-home';
    }
    //@debug color($color);
    background-color: color($color);
  }
 
  @if $bgImg != null {
    @if $name == 'home' {
      background: {
        attachment: fixed;
        image: url($bgImg);
        position: $bgPos;
        repeat: no-repeat;
        size: cover;
      }
    } @else {
      @include breakpoint($tablet-small) {
        background: {
          attachment: fixed;
          image: url($bgImg);
          position: $bgPos;
          repeat: no-repeat;
          size: cover;
        }
      }
    } 
  }

  bottom: 0px;
  display: block;
  height: 100vh;
  left: 0px;
  position: fixed;
  right: 0px;
  top: 0px;
  z-index: $z-index;
}
