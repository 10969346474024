.f-field-wrapper {
  @include clearfix;

  margin-bottom: .75rem;
  
  &.textarea {
  }

  &.input-text {
  }

  &.submit {
    margin-top: 2rem;
    text-align: right;
  }
  
  //border: 1px solid #fff;
}  

.f-label {
  display: block;
  cursor: pointer;
  font-weight: normal;
  line-height: 1.2;
  margin-bottom: 0.5rem;

  @include breakpoint($tablet) {
    float: left;
    margin-right: 2%;
    margin-top: 0.25rem;
    text-align: right;
    width: 20%;
  }
}
 
.f-field {
  border: 0 none;
  margin: 0;
  padding: 0.25rem 0.5rem;
  width: 100%;

  .textarea & {
    height: 10rem;
  }

  @include breakpoint($tablet) {
    width: 78%;
  }
}

.f-note {
  @include type-scale('base');

  @include breakpoint($tablet) {
    margin-left: 22%;
  }
}
