@charset 'UTF-8';

// Libraries 
// ---------
@import 'lib/lib',
'normalize-css/normalize',
'lib/animate';

// Variables
// ---------
@import 
'variables/var-globals',
'variables/var-colors',
'variables/var-typography',
'variables/var-breakpoints',
'variables/var-layouts';

// Helpers 
// -------
@import 
'helpers/h-helpers',
'helpers/h-layouts',
'helpers/h-components';

// Fonts
// -----
@import 
'fonts/Amble',
'fonts/Vollkorn';

// Vendors
// -------
@import
'vendor/webflow',
'fancybox/dist/jquery.fancybox';
//'slick-carousel/slick/slick.scss',
//'slick-carousel/slick/slick-theme.scss';


// Reset and base files (atoms)
// ----------------------------
@import
'base/b-reset',
'base/b-base',
//'base/main',
'base/b-typography';

// Layouts
// -------
@import 
'layout/l-layout',
'layout/l-nav',
'layout/l-home',
'layout/l-brand';

// components
// -------------------------------
@import
//'components/c-components',
//'components/c-button',
'components/c-start-button',
'components/c-hero-text',
'components/c-home',
'components/c-sieh-an',
'components/c-slider',
'components/c-suche',
'components/c-finde',
'components/c-starte',
'components/c-footer',
'components/c-modal',
'components/c-datenschutz',
'components/c-forms',
'components/c-ap';

// themes
// --------------------
//@import 
//'themes/jquery-ui-1.10.3.custom',
//'themes/fancybox',
//'themes/vertical-timeline-custom';
//'themes/liquidslider_custom';

// utilities
// --------------------
@import 'utilities/u-utilities';

// pages
// --------------------
//@import 
//'pages/frontpage',
//'pages/weCreateObjects';

// shame
// --------------------
@import 
'shame';
