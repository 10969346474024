.suche__content {
  width: 100%;
  float: right;

  @include breakpoint($mobile-landscape) {
    width: 80%;
  }

  @include breakpoint($tablet-small (orientation portrait)) {
    width: 70%;
  }

  @include breakpoint(orientation landscape) {
    @include breakpoint($tablet-small) {
      width: 68%;
    }

    @include breakpoint($tablet) {
      width: 50%;
    }
  }
}

.suche__headline {
  @include breakpoint($tablet-small) {
    margin-left: -10%;
  }

  @include breakpoint($tablet-landscape) {
    margin-left: -20%;
  }
}

.bg-suche-wrapper {
  @include section-keyvisual(null, 16);
}

.bg-suche-front {
  @include section-keyvisual(null, 3, '../img/wmd-bg-suche_front_v2.png', 15% 0);
  
  @include breakpoint($tablet-small) {
    background-position: left bottom;
    background-size: 75%;
  }
  
  @include breakpoint(($tablet) (orientation landscape)) {
    background-position: 15% 0;
    background-size: cover;
  }

  @include breakpoint($computer) {
    background-position: 0 0;
  }
}

.bg-suche--back {
  @include section-keyvisual('suche', 15, '../img/wmd-bg-suche_back_v2.jpg', 15% 0);

  @include breakpoint($tablet-small) {
    background-position: left bottom;
  }

  @include breakpoint($computer) {
    background-position: 0 0;
  }
}
