.home__content {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
  display: block;
  position: relative;
  text-align: left;
  top: 4rem;
  width: 100%;

  @include breakpoint(orientation portrait) {
    @include breakpoint($tablet) {
      top: 6rem;
      //top: 9.25rem;
      //width: 84%;
    }
    @include breakpoint($tablet) {
      top: 7.5rem;
    }
  }
  
  @include breakpoint(orientation landscape) {
    @include breakpoint($tablet) {
      top: 6rem;
    }

    @include breakpoint($computer) {
      top: 9.25rem;
    }
  }
}

.bg-home {
  @include section-keyvisual('home', -1, '../img/wmd-bg-home-v3.png', right bottom);

  @include breakpoint(orientation portrait) {
    background-size: 205%;

    @include breakpoint($mobile-landscape) {
      background-position: 90% bottom;
      background-size: cover;
    }

    @include breakpoint($tablet-small) {
      //background-position: right bottom;
      background-size: 190%;
    }

    //@include breakpoint($tablet-landscape) {
      //background-size: 175%;
    //}

    @include breakpoint($computer) {
      background-size: cover;
    }
  }

  @include breakpoint(orientation landscape) {
    @include breakpoint($tablet) {
      background-size: 100%;
    }

    @include breakpoint($computer) {
      background-size: cover;
    }
  }
}
