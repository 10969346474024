.brand {
  height: 4rem;
  padding: 0;
  width: 4rem;

  @include breakpoint($tablet) {
    height: 6.25rem;
    width: 6.25rem;
  }

  @include breakpoint($tablet-landscape (orientation portrait)) {
    height: 7.75rem;
    width: 7.75rem;
  }

  @include breakpoint($computer-large) {
    height: 7.75rem;
    width: 7.75rem;
  }
}

.text-block {
  @include ff('secondary');
  @include type-scale(base);
  line-height: 1.25;

  float: left;
  clear: none;
  color: color('white');
  display: none;
  font-style: normal;
  margin-left: 1rem;
  margin-top: .875rem;
  position: static;

  em {
    @include ff('secondary-italic');
  }
  
  @include breakpoint($tablet-small) {
    display: block;
  }
  
  @include breakpoint($tablet) {
    @include adjust-font-size-to(26px, 1.125);

    margin-left: 1.5rem;
    margin-top: 1.125rem;
  }
  
  @include breakpoint($tablet-landscape (orientation portrait)) {
    @include adjust-font-size-to(36px, 1.5);
  }
    
  @include breakpoint($computer-large) {
    @include adjust-font-size-to(36px, 1.5);

    //margin-left: 1.5rem;
    //margin-top: 1.125rem;
  }
}
