// Typography mixins
// -----------------

// Font weight
// -----------
@mixin fw($weight, $target: primary) {
  font-weight: map-fetch($font-weights, $target $weight);
}

// Font Family
// -----------
@mixin ff($family: primary, $map: $font-families) {
  font-family: map-get($map, $family);
  font-style: normal;
  font-weight: map-get($font-weight-default, $family);
}

// Type-scale
// ----------
// Gets font-size and baseline from typography map located
// in variables/typography and creates the necessary
// font-size and line-height property in CSS with the
// compass vertical rhythm.
//
// This uses the @include adjust-font-size-to mixin and
// requires Compass Vertical Rhythm to be used.
//
// https://medium.com/p/8e51498c3713
// ==========
@mixin type-scale($scale, $map: $type-scale) {
  $base-lines: map-fetch($map, $scale base-lines);
  $font-size: map-fetch($map, $scale font-size);
  //@include breakpoint($computer) {
  //  $font-size: $font-size * 1.2;
  //}
  @include adjust-font-size-to($font-size, $base-lines);
  @include padding-leader(.125);
  @include padding-trailer(.125);
}

// Text overflow
// -------------
// Requires inline-block or block for proper styling
@mixin text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// Text input
// ------------
// Calls addresses all text inputs in one selector
$text-input-types: text, password, date, datetime, datetime-local, month, week, email, number, search, tel, time, url, color !default;

@mixin text-input($types: $text-input-types) {
  $selectors: ();
  @each $type in $types {
    $selector: unquote('input[type='#{$type}']');
    $selectors: append($selectors, $selector, comma);
  }

  & #{$selectors} {
    @content;
  }
}

@mixin hyphen($bp) {
  display: inline;
  &:before {
    content: '-';
  }

  @if $bp == 'mobile-landscape' {
    @include breakpoint($mobile-landscape) {
      @include clear-hyphen;
    }
  }

  @if $bp == 'tablet-small' {
    @include breakpoint($tablet-small) {
      @include clear-hyphen;
    }
  }

  @if $bp == 'tablet' {
    @include breakpoint($tablet) {
      @include clear-hyphen;
    }
  }

  @if $bp == 'tablet-landscape' {
    @include breakpoint($tablet-landscape) {
      @include clear-hyphen;
    }
  }

  @if $bp == 'computer' {
    @include breakpoint($computer) {
      @include clear-hyphen;
    }
  }
}

@mixin clear-hyphen {
  display: none;
  &:before {
    content: '';
  }
}
