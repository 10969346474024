
// Colors
// ======
// For project colors. 
//
// Approach: 
// ---------
// @LandonSchropp's approach of naming color variables.
// - http://davidwalsh.name/sass-color-variables-dont-suck
// - http://chir.ag/projects/name-that-color/
//
// Usage:
// ------
// .test {
//   background-color: color(shamrock);
// }
//
// OPTIONAL:
// --------
// Sublime gutter: https://sublime.wbond.net/packages/Gutter%20Color 
// See the colors you used in the gutter (Awesome if you use sublime text)
// ==========

$colors-greyscale: (
  dark-grey: #333,
  medium-dark-grey: #666,
  medium-grey: #999,
  medium-light-grey: #ccc,
  light-grey: #eee,
  super-light-grey: #f5f5f5,
);

$colors: (
  brand-primary: #3467cc, //$daheim-blau
  brand-secondary: #f90, //$daheim-orange und $orange
  //brand-accent-1: #c00, //rot
  default-text-color: #333,
  default-link-color: #69c,
  dark-blue: #036,
  brand-primary-80: rgba(#3467cc, .8), //80% $daheim-blau
  brand-primary-60: rgba(#3467cc, .6), //80% $daheim-blau
  brand-primary-15: rgba(#3467cc, .15), //10% $daheim-blau
  brand-primary-10: rgba(#3467cc, .1), //10% $daheim-blau
  brand-secondary-80: rgba(#f90, .8), //80% $daheim-blau
  'black': #000,
  'black-75': rgba(#000, .75), //75% Schwarz
  'white': #fff,
  'alert': #c00, //rot
  'success': #008000, //green
  'selection': #3467cc, // daheim-blau
  'danger': #c0392b,
  'warning': #f1c40f,
  'info': #2980b9,
  'bg-home': #b0b9ca,
  'bg-sieh-an': #f54263,
  'bg-suche': #598a62,
  'bg-finde': #96b3c8,
  'bg-starte': #e1a23d,
);
