.ds ol {
  list-style: upper-roman;
  padding-left: 2rem;
}

.ds ol li, 
.ds ul li {
  margin-top: 2rem;
}

.ds ol li:first-child {
  //margin-top: 0;
}

.ds ol ol {
  list-style: decimal;
}

.ds ol ol li, 
.ds ol ol li:first-child {
  margin-top: 1rem;
}

.ds ol ol ol {
  list-style: lower-alpha;
  margin-bottom: 1rem;
}

.ds ol ol ol li, 
.ds ol ol ol li:first-child {
  margin-top: .5rem;
}

.ds ol ol ol ol {
  list-style: lower-roman;
  margin-bottom: 1rem;
}
