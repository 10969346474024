.finde__content {
  width: 100%;

  @include breakpoint($mobile-landscape) {
    width: 80%;
  }

  @include breakpoint($tablet-small (orientation portrait)) {
    width: 75%;
  }

  @include breakpoint(orientation landscape) {
    @include breakpoint($tablet-small) {
      width: 75%;
    }

    @include breakpoint($tablet) {
      width: 65%;
    }
  }
}

.bg-finde-wrapper {
  @include section-keyvisual(null, 18);
}

.bg-finde-front {
  @include section-keyvisual(null, 3, '../img/wmd_finde_front_v3.png', 50% 0);
  
  @include breakpoint($tablet-small) {
    background-position: right bottom;
    background-size: 86%;
  }
  
  @include breakpoint(($tablet) (orientation landscape)) {
    background-position: 75% 0;
    background-size: cover;
  }
  
  @include breakpoint($computer) {
    background-position: 100% 0;
  }
}

.bg-finde-back {
  @include section-keyvisual('finde', 17, '../img/wmd_finde_back_v3.jpg', 50% 0);
  
  @include breakpoint($tablet-small) {
    background-position: 40% bottom;
  }

  @include breakpoint($tablet-landscape) {
    background-position: 60% 0;
  }
  
  @include breakpoint($computer) {
    background-position: 100% 0;
  }
}
