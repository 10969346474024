// Generated by Font Squirrel (http://www.fontsquirrel.com) on June 29, 2013 03:58:51 PM America/New_York

@font-face {
  font-family: 'VollkornRegular';
  font-style: normal;
  font-weight: normal;
  src: url('/fonts/Vollkorn/Vollkorn-Regular-webfont.eot');
  src: url('/fonts/Vollkorn/Vollkorn-Regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/Vollkorn/Vollkorn-Regular-webfont.woff') format('woff'),
  url('/fonts/Vollkorn/Vollkorn-Regular-webfont.ttf') format('truetype'),
  url('/fonts/Vollkorn/Vollkorn-Regular-webfont.svg#VollkornRegular') format('svg');
}

@font-face {
  font-family: 'VollkornBold';
  font-style: normal;
  font-weight: normal;
  src: url('/fonts/Vollkorn/Vollkorn-Bold-webfont.eot');
  src: url('/fonts/Vollkorn/Vollkorn-Bold-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/Vollkorn/Vollkorn-Bold-webfont.woff') format('woff'),
  url('/fonts/Vollkorn/Vollkorn-Bold-webfont.ttf') format('truetype'),
  url('/fonts/Vollkorn/Vollkorn-Bold-webfont.svg#VollkornBold') format('svg');
}

@font-face {
  font-family: 'VollkornItalic';
  font-style: normal;
  font-weight: normal;
  src: url('/fonts/Vollkorn/Vollkorn-Italic-webfont.eot');
  src: url('/fonts/Vollkorn/Vollkorn-Italic-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/Vollkorn/Vollkorn-Italic-webfont.woff') format('woff'),
  url('/fonts/Vollkorn/Vollkorn-Italic-webfont.ttf') format('truetype'),
  url('/fonts/Vollkorn/Vollkorn-Italic-webfont.svg#VollkornItalic') format('svg');
}

@font-face {
  font-family: 'VollkornBoldItalic';
  font-style: normal;
  font-weight: normal;
  src: url('/fonts/Vollkorn/Vollkorn-BoldItalic-webfont.eot');
  src: url('/fonts/Vollkorn/Vollkorn-BoldItalic-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/Vollkorn/Vollkorn-BoldItalic-webfont.woff') format('woff'),
  url('/fonts/Vollkorn/Vollkorn-BoldItalic-webfont.ttf') format('truetype'),
  url('/fonts/Vollkorn/Vollkorn-BoldItalic-webfont.svg#VollkornBoldItalic') format('svg');
}
