.l-section {
  @include clearfix;

  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  display: block;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  margin-bottom: 4rem;
  justify-content: center;
  overflow: hidden;
  position: relative;
  z-index: 1000;
  
  @include breakpoint(orientation landscape) {
    margin-bottom: 50vh;//4rem;//Höhe nav

    &.starte {
      margin-bottom: 4rem;
    }
  }
  
  &.home, 
  &.sieh-an {
    min-height: 100vh;
  }
  
  &.footer {
    margin-bottom: 0;
    padding-top: 4rem;
    padding-bottom: 1rem;
  }

  @include breakpoint($tablet-landscape) {
    //margin-bottom: 6.25rem;//Höhe nav
  }
  
  //border: 1px solid #fff;
}

.container {
  @include clearfix;

  display: block;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  max-width: 80%;
  margin-right: auto;
  margin-left: auto;
  overflow: visible;
  position: relative;
  text-align: left;
  z-index: 101;
}
