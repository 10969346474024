// THEME RESET
// -----------
// Personal resets in addition to Normalize.scss and HTML5 Boilerplate v5.2.0
// ===========

// Use border-box for everything
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

// Resets margins and paddings for headers, ul, ol and p
h1, 
h2, 
h3, 
h4, 
h5, 
h6, 
ul, 
ol,
dl,
li, 
p {
  margin: 0;
  padding: 0;
}

// Removes all decimals and discs from li
//li {
//  list-style: none;
//}

// Completely resets form items
// ----------------------------
// Super hard reset that removes all borders 
// and radiuses of all form items. 
// ============================
input,
textarea,
button {
  //-moz-appearance: none;
  //-webkit-appearance: none;
  //appearance: none; //custom
  border: 0;
  border-radius: 0;
  outline: 0;
}

// Set Image and Objects to have a max-width of 100%
embed,
iframe,
img,
object,
video {
  max-width: 100%;
}

// Set Img height and width to auto for IE8
img {
  // IE8 
  height: auto;
  max-height: 100%;
  //width: auto;
  width: 100%;
}

// HTML5 Boilerplate
// -----------------------------------------------------------------------------

// A better looking default horizontal rule
hr {
  border: 0;
  border-top: 1px solid color(medium-light-grey, $colors-greyscale);
  display: block;
  height: 1px;
  margin: 1em 0;
  padding: 0;
}

// Remove the gap between audio, canvas, iframes,
// images, videos and the bottom of their containers:
// https://github.com/h5bp/html5-boilerplate/issues/440
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

// Remove default fieldset styles.
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

// Allow only vertical resizing of textareas.
textarea {
  resize: vertical;
}
