.starte__content {
  float: right;
  width: 100%;

  @include breakpoint($mobile-landscape) {
    width: 80%;
  }

  @include breakpoint($tablet-small (orientation portrait)) {
    width: 70%;
  }

  @include breakpoint(orientation landscape) {
    @include breakpoint($tablet-small) {
      width: 68%;
    }

    @include breakpoint($tablet) {
      width: 50%;
    }
  }
}

.bg-start-wrapper {
  @include section-keyvisual(null, 30);
}

.bg-starte-front {
  @include section-keyvisual(null, 3, '../img/wmd_starte_front_v5.png', 15% 0);
  
  @include breakpoint($tablet-small) {
    background-position: left bottom;
    background-size: 85%;//75%;
  }
  
  @include breakpoint(($tablet) (orientation landscape)) {
    background-position: 15% 0;
    background-size: cover;
  }

  @include breakpoint($computer-large) {
    background-position: 0 0;
  }
}

.bg-starte-back {
  @include section-keyvisual('starte', 19, '../img/wmd_starte_back_v5.jpg', 15% 0);

  @include breakpoint($computer) {
    background-position: 0 0;
  }
}
