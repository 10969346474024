.nav-menu {
  background-color: transparent;
  margin-left: auto;
  margin-right: auto;
  top: 70px;
  width: 80%;
}

.menu-label {
  float: left;
  padding-right: 0.125rem;
  padding-top: 0.25rem;

  @include breakpoint($tablet-landscape) {
    font-size: 1.4rem;
    padding-right: 0;
    padding-top: 0.8rem;
  }
}

.menu-button-2 {
  bottom: 0px;
  font-size: 1rem;
  position: absolute;
  right: 0px;
  top: 0px;
}

.navbar-2 {
  background-color: transparent;
  left: 0px;
  overflow: visible;
  position: fixed;
  right: 0px;
  top: 0px;
  z-index: 1999;
}

.nav__link-wrapper {
  float: right;
  position: static;
  width: 270px;
}

.icon {
  //@include type-scale(t2);
  float: right;
  font-size: 2.25rem;
  //color: color('white');
  line-height: 1;
  
  @include breakpoint($tablet-landscape) {
    //@include type-scale(t3);
    font-size: 3.375rem;
  }
}
