.sieh-an__content {  
  @include breakpoint(orientation portrait) {
    padding-top: 10%;
  }
  
  @include breakpoint(orientation landscape) {
    @include breakpoint($tablet) {
      padding-top: 5%;
    }

    @include breakpoint($computer) {
      padding-top: 2.5%;
    }

    @include breakpoint($computer-large) {
      padding-top: 7.5%;
    }
  }
}

.sieh-an__headline {
  @include ff('bold');
  margin-top: 1rem;

  @include breakpoint(orientation portrait) {
    @include type-scale(t2);
    
    @include breakpoint($tablet-small) {
      @include type-scale(t4);
      //margin-top: 2rem;
    }

    @include breakpoint($tablet) {
      @include type-scale(t5);
      //margin-top: 4rem;
    }

    @include breakpoint($tablet-landscape) {
      @include type-scale(t6);
      //margin-top: 4rem;
    }
  }

  @include breakpoint(orientation landscape) {
    @include type-scale(t1);
    
    @include breakpoint($tablet-small) {
      @include type-scale(t2);
      width: 80%;
    }
    
    @include breakpoint($tablet) {
      @include type-scale(t3);
    }
    
    @include breakpoint($tablet-landscape) {
      @include type-scale(t4);
    }

    @include breakpoint($computer) {
      @include type-scale(t5);
    }

    @include breakpoint($computer-large) {
      @include type-scale(t6);
    }
  }
}

.sieh-an__link-area {
  background: {
    image: url('../img/video-thumbnail_Andrea-Quibeldey.jpg');
    position: top center;
    repeat: no-repeat;
    size: cover;
  }
  display: block;
  padding-bottom: 1rem;
  position: relative;
  margin-top: 1rem;
  text-decoration: none;
  
  &.charlotte {
    background: {
      image: url('../img/video-placeholder_Azubi-Charlotte_v1.jpg');
    }
  }

  @include breakpoint($tablet-small) {
    background: none;
    float: right;
    width: 90%;

    &.charlotte {
      background: none;
    }
    
    @include breakpoint(orientation portrait) {
      margin-top: 3.5rem;
    }
  }  

  @include breakpoint(orientation landscape) {
    @include breakpoint($tablet-small) {
      margin-top: 2.5rem;
      padding-right: 1rem;
      width: 80%;
    }
  }
}

.link-area__label {
  @include type-scale(t1);

  background: {
    image: url('../img/youtube-icon.svg');
    repeat: no-repeat;
    position: center bottom;
  }
  padding-bottom: 4rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  text-align: center;
  
  @include breakpoint(orientation portrait) {
    background-size: 20%;

    @include breakpoint($tablet-small) {
      @include type-scale(t2);

      background: {
        position: left top;
        size: 20%;
      }
      padding-left: 25%;
      padding-right: 0;
      padding-top: 0;
      padding-bottom: 0;
      text-align: left;
    }

    @include breakpoint($tablet) {
      @include type-scale(t3);
      background-position: left .75rem;
    }

    @include breakpoint($tablet-landscape) {
      @include type-scale(t4);
    }
  }

  @include breakpoint(orientation landscape) {
    background-size: 15%;
    
    @include breakpoint($tablet-small) {
      background: {
        position: left top;
        size: 20%;
      }
      
      padding-bottom: 1rem;
      padding-left: 25%;
      padding-right: 0;
      padding-top: 0;
      text-align: left;
    }
    
    @include breakpoint($tablet) {
      @include type-scale(t2);

      background-position: 0 .5rem;
      padding-bottom: 1rem;
    }
    
    @include breakpoint($tablet-landscape) {
      @include type-scale(t3);
      padding-bottom: 1rem;
    }
    
    @include breakpoint($computer) {
      @include type-scale(t4);
      padding-bottom: 1rem;
    }
    
    @include breakpoint($computer-large) {
      @include type-scale(t4);
      padding-bottom: 4rem;
    }
  }
}

.bg-sieh-an-wrapper {
  @include section-keyvisual(null, 14);
}

.bg-sieh-an-front {
  @include section-keyvisual(null, 3, '../img/wmd-bg-sieh-an-front.png', 100% 0);

  &.bg-sieh-an-front__charlotte {
    background-image: url('../img/wmd_Azubi-Charlotte_front_v2.png');
  }
  
  @include breakpoint(orientation portrait) {
    @include breakpoint($tablet-small) {
      background: {
        image: url('../img/wmd_sieh-an_front-complete_v2.png');
        position: right bottom;
        //repeat: no-repeat;
        size: 40%;
      }

      &.bg-sieh-an-front__charlotte {
        background: {
          image: url('../img/wmd_Azubi-Charlotte_front_v2.png');
          size: 90%;
        }
      }
    }
  }

  @include breakpoint(orientation landscape) {
    @include breakpoint($tablet) {
      background-position: 60% 0;
      background-size: cover;

      &.bg-sieh-an-front__charlotte {
        background-position: 80% 0;
      }
    }

    @include breakpoint($tablet-landscape) {
      background-position: 60% 0;
    }
  }
}

.bg-sieh-an-back {
  @include section-keyvisual('sieh-an', 13, null);
}
  
.bg-sieh-an-back__slide {
  @include section-keyvisual(null, 13, '../img/wmd-bg-sieh-an-back.jpg', left top);
  
  @include breakpoint($tablet-small) {
    //background-position: 100% top;

    &.charlotte {
      background: {
        image: url('../img/wmd_Azubi-Charlotte_back_v2.jpg');
        position: right top;
      }
    }
  }

  @include breakpoint($tablet-landscape) {
    background-position: left top;
  }
}

