.fancybox-close-small::after {
  color: color('white');
}

.c-modal {
  @include border-radius(8px);
  //background-color: color(medium-dark-grey, $colors-greyscale);
  background-color: color('brand-primary');
  display: none;
  padding: 2rem;
  width: 80%;

  &.finde-kaffee {
    background-color: color('brand-secondary');
    max-width: 48rem;
    padding: 1rem;
  }

  &.finde-social, 
  &.imprint {
    max-width: 48rem;
  }

  &.suche-kennenlernen {
    padding: 1rem;
  
    @include breakpoint(orientation portrait) {
      @include breakpoint($tablet-landscape) {
        padding: 2rem;
      }
    }

    @include breakpoint(orientation landscape) {
      @include breakpoint($tablet-landscape) {
        padding: 2rem;
      }
    }
  }
  
  &.starte-bewerbung {
    height: 80vh;
    //overflow-y: scroll;

    @include breakpoint(orientation portrait) {
      @include breakpoint($tablet-small) {
        height: auto;
      }
    }
    
    @include breakpoint(orientation landscape) {
      @include breakpoint($tablet) {
        height: auto;
      }
    }
  }

  &.suche-frage, 
  &.suche-neugierig {
    max-width: 48rem;
    padding: 1rem;

    @include breakpoint($tablet-small) {
      padding: 2rem;
    }
  }
  
  &.suche-frage {
    max-width: 54rem;
  }
}

.suche-frage {
  text-align: center;
  h3 {
    @include type-scale(t1);
  }
  p {
    margin-top: 0.5rem;
  }
  
  @include breakpoint(orientation landscape) {

    @include breakpoint($tablet-small) {
      h3, 
      p {
        //@include fw(bold);
        @include type-scale(t1);
      }
    }

    @include breakpoint($tablet-landscape) {
      h3, 
      p {
        @include type-scale(t2);
      }
    }
  }
  
  @include breakpoint(orientation portrait) {
    @include breakpoint($mobile-landscape) {
      p {
        @include type-scale(t1);
      }
    }

    @include breakpoint($tablet) {
      h3,
      p {
        @include type-scale(t3);
      }
    }

    @include breakpoint($tablet-landscape) {
      h3,
      p {
        @include type-scale(t4);
      }
    }
  }
}

.c-modal__social-wrapper {
  @include clearfix;
  //margin-left: auto;
  //margin-right: auto;
  //max-width: 46rem;
  text-align: center;

  .c-modal__social-label {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  //border: 1px solid #fcf;
}

.c-modal__social {
  background: {
    repeat: no-repeat;
    size: cover;
    position: center;
  }
  display: inline-block;
  //float: left;
  margin-bottom: 1rem;
  margin-left: 2rem;
  margin-right: 2rem;
  width: 6rem;
  height: 6rem;

  &.facebook {
    background-image: url('../img/logo-facebook-negative.svg');    
  }

  &.twitter {
    background-image: url('../img/twitter-bird-blue-bgs.svg');    
  }
 
  &.youtube {
    background-image: url('../img/logo-youtube.svg');    
  }
  
  //border: 1px solid #fff;
}

.c-modal__service-wrapper {
  margin-top: 1rem;
}

.c-modal__service {
  @include border-radius(4px);
  background-color: darken(color('brand-primary'), 10%);
  overflow: hidden;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  //width: 80%;
  
  &:last-child {
    margin-bottom: 0;
  }
  
  //@include breakpoint(orientation portrait) {
    @include breakpoint($tablet-landscape) {
      padding-bottom: 2rem;
      margin-bottom: 2rem;
    }
  //}
  
  @include breakpoint(orientation landscape) {
    @include breakpoint($computer) {
      float: left;
      margin-right: 3%;
      width: 30.333%;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.c-modal__service-link {
  display: inline-block;
  text-decoration: none;
}

.c-modal__service-media {
  margin-bottom: 1rem;
}

.c-modal__service-headline {
  @include type-scale(t2);
  //color: color('brand-secondary');
  padding-left: 1rem;
  padding-right: 1rem;
  
  @include breakpoint(orientation portrait) {
    @include breakpoint($tablet) {
      @include type-scale(t3);
    }

    @include breakpoint($tablet-landscape) {
      @include type-scale(t4);
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }

  @include breakpoint(orientation landscape) {
    @include breakpoint($computer) {
      padding-left: 2rem;
      padding-right: 2rem;
    }

    @include breakpoint($computer-large) {
      @include type-scale(t3);
    }
  }
}

.modal-hyphen--hg {
  @include hyphen('mobile-landscape');
}

.c-modal__service-description {
  padding-left: 1rem;
  padding-right: 1rem;
  
  @include breakpoint(orientation portrait) {
    @include breakpoint($tablet-landscape) {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }

  @include breakpoint(orientation landscape) {
    @include breakpoint($computer) {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
}
