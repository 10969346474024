@include establish-baseline;

// Typography base
// ---------------
// Sets typography for all of document 
// ===============

body, .c-modal, input, textarea, button {
  @include ff(primary);
  @include type-scale(base);
  // Optmizes font rendering for legibility.
  // May break with some browsers, comment out as necessary.
  text-rendering: optimizeLegibility;
}

// Adaptation of Lobotomized Owls Selector on p elements
// http://alistapart.com/article/axiomatic-css-and-lobotomized-owls

// p + p,
// p + figure,
// p + img,
// figure + p,
// img + p,
p + *,
* + p {
  @include margin-leader(.5);
  //margin-top: 1rem;//vr(1);
}

// h1
// -----------------------------------------------------------------------------
.t-h1 {
  @include ff(bold);
  @include type-scale(t3);

  
  @include breakpoint(orientation portrait) {
    @include breakpoint($mobile-landscape) {
      @include type-scale(t5);
    }

    @include breakpoint($tablet) {
      @include type-scale(t6);
    }

    @include breakpoint($tablet-landscape) {
      @include type-scale(t7);
    }
  }

  @include breakpoint(orientation landscape) {
    @include breakpoint($tablet-small) {
      @include type-scale(t4);
    }

    @include breakpoint($tablet) {
      @include type-scale(t5);
    }

    @include breakpoint($tablet-landscape) {
      @include type-scale(t6);
    }

    @include breakpoint($computer-large) {
      @include type-scale(t7);
    }
  }
}

// h2
// -----------------------------------------------------------------------------
.t-h2 {
  @include ff(bold);
  @include type-scale(t5);
  
  @include breakpoint($mobile-landscape) {
    @include type-scale(t6);
  }
  
  @include breakpoint($tablet-landscape) {
    @include type-scale(t7);
  }
  
  //@include breakpoint($computer) {
  //  @include type-scale(t8);
  //}
  
  //margin-bottom: 0;
  //margin-top: 4rem;

  &.einzug {
    margin-left: 0%;
  }

  &.einzug{
    &.first {
      margin-top: 0;
    }
  }

  &.first {
    margin-top: 10px;
  }

  @include breakpoint($mobile-landscape) {
    //font-size: 54px;

    &.sieh-an__headline {
      margin-bottom: 72px;
      width: 75%;
    }

    &.einzug {
      margin-left: -20%;
    }

    &.einzug.first {
      margin-left: 20px;
    }
  }

  //@include breakpoint($tablet-landscape) {
    //font-size: 81px;
  //}
}

// h3
// -----------------------------------------------------------------------------
.t-h3 {
  @include ff(bold);
  @include type-scale(t3);

  @include breakpoint($mobile-landscape) {
    @include type-scale(t4);
  }
}

// h4
// -----------------------------------------------------------------------------
.t-h4 {
  //color: color('white');
  display: block;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  font-size: 24px;
  float: none;
  font-family: 'AmbleRegular', sans-serif;
  font-weight: 400;
  line-height: 1.2;
  margin-bottom: 0px;
  margin-top: 0px;
  text-align: left;

  @include breakpoint($mobile-landscape) {
    font-size: 36px;
  }
}

// p
// -----------------------------------------------------------------------------
p {
  //color: color('white');
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;

  &.einzug--links {
    margin-left: 0%;
  }

  @include breakpoint($mobile-landscape) {
    font-size: 21px;
  }

  @include breakpoint($tablet-landscape) {
    font-size: 24px;

    @include breakpoint(orientation landscape) {
      &.einzug--links {
        margin-left: 20%;
      }
    }
  }
}

// nav-link
// -----------------------------------------------------------------------------
.nav-link {
  background-image: -webkit-linear-gradient(270deg, rgba(52, 103, 204, .7), rgba(52, 103, 204, .7));
  background-image: linear-gradient(180deg, rgba(52, 103, 204, .7), rgba(52, 103, 204, .7));
  border-bottom: 1px solid color(color'white');
  color: color('white');
  display: block;
  float: right;
  font-size: 16px;
  line-height: 21px;
  margin-left: 0px;
  padding: 10px;
  position: static;
  width: 100%;

  &.w--current {
    background-color: color(brand-secondary);
    background-image: none;
    color: color('white');
    font-family: 'AmbleBold', sans-serif;
  }

  &.last {
    border-bottom-width: 0px;
  }

  @include breakpoint($mobile-landscape) {
    .nav-link {
      font-size: 18px;
      line-height: 21px;
      padding: 16px;
    }
  }

  @include breakpoint($tablet) {
    font-size: 21px;
    line-height: 24px;
    padding: 18px 19px;
  }

  @include breakpoint($tablet-landscape) {
    font-size: 24px;
    line-height: 36px;
  }
}

// diverse
// -----------------------------------------------------------------------------
a {
  color: color('white');
  text-decoration: underline;
}

.link {
  &.inline {
    background-color: color(brand-secondary);
    border: 0 none;//color('white');//rgba(color('black'), .5);
    color: color('white');//rgba(color('black'), .5);
    display: inline;
    padding-bottom: 4px;
    padding-left: 4px;
    padding-right: 4px;
    text-decoration: none;

    @include breakpoint($tablet-small) {
      //@include breakpoint(orientation portrait) {
        background-color: transparent;
        border-bottom-style: dotted;
        border-bottom-width: 1px;
        border-color: color(brand-secondary);
        color: color(brand-secondary);
        padding-bottom: 0;
      //}
    }
  }

  &.inline.w--current {
    display: inline-block;
    padding: 0px 4px;
    text-decoration: none;
  }
}

strong,
b {
  @include ff(bold);
}

em, 
i {
  @include ff(italic);

  strong,
  b {
    @include ff(bold-italic);
  }
}

pre {
  white-space: pre;
}
