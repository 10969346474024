.slider {
  background: none;
  height: 80vh;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  @include breakpoint((orientation landscape)) {
    height: 90vh;

    @include breakpoint($tablet-small) {
      height: 80vh;
    }
  }
}

.slider__mask {
  background-color: transparent;
  //color: transparent;
}

.slider__arrow {
  background: {
    color: color('brand-secondary');
    repeat: no-repeat;
    size: 30%;
  }
  border-radius: 100%;
  bottom: auto;
  //color: white;
  cursor: pointer;
  font-size: 1rem;
  height: 2.5rem;
  //margin: auto;
  overflow: hidden;
  position: absolute;
  width: 2.5rem;
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  top: 50%;
  user-select: none;
  
  &.left {
    background: {
      image: url('../img/caret-left-white.svg');
      position: 45% 50%;
    }
    left: 1rem;
    right: auto;
  }
    
  &.right {
    background: {
      image: url('../img/caret-right-white.svg');
      position: 55% 50%;
    }
    left: auto;
    right: 1rem;
  }

  @include breakpoint($tablet-small) {
    height: 3.75rem;
    width: 3.75rem;

    @include breakpoint(orientation portrait) {
      top: 64%;
    }
  }

  @include breakpoint($tablet) {
    height: 4.75rem;
    width: 4.75rem;

    @include breakpoint(orientation portrait) {
      top: 60%;
    }
  }
  
  @include breakpoint($tablet-landscape) {
    height: 6.5rem;
    width: 6.5rem;

    @include breakpoint(orientation portrait) {
      top: 57%;
    }
  }
}
