.c-ap {
  @include clearfix;
  margin-bottom: 2rem;
}

.c-ap__media { 
//  float: left;
//  margin-right: 5%;
  margin-bottom: 1rem;
  width: 50%;
  
  @include breakpoint(orientation portrait) {
    @include breakpoint($tablet) {
      float: left;
      margin-right: 5%;
      width: 40%;
    }
  }
  
  @include breakpoint(orientation landscape) {
    float: left;
    margin-right: 5%;
    width: 40%;
  }
}

.c-ap__data { 
  @include breakpoint(orientation portrait) {
    @include breakpoint($tablet) {
      margin-left: 45%;
    }
  }
  
  @include breakpoint(orientation landscape) {
    margin-left: 45%;
  }
}

.c-ap__name {
  @include type-scale(t2);

  @include breakpoint(orientation portrait) {
    @include breakpoint($tablet-small) {
      @include type-scale(t3);

      & + p {
        margin-top: 0;
      }
    }

    @include breakpoint($tablet) {
      padding-top: 0;
    }

    @include breakpoint($tablet-landscape) {
      @include type-scale(t4);
    }
  }
  
  @include breakpoint(orientation landscape) {
    & + p {
      margin-top: 0;
    }

    @include breakpoint($tablet-small) {
      @include type-scale(t3);
      padding-top: 0;
    }

    @include breakpoint($tablet-landscape) {
      @include type-scale(t4);
      padding-top: 0;
    }
  }
}