.c-start-button {
  bottom: 2rem;
  display: block;
  position: absolute;
  text-align: center;
  text-decoration: none;
  width: 100%;

  @include breakpoint(orientation landscape) {
    @include breakpoint($mobile-landscape) {
      bottom: 1rem;
      margin-left: 10%;
      text-align: left;
      width: 80%;
    }

    @include breakpoint($tablet-small) {
      bottom: 2rem;
    }

    @include breakpoint($tablet-landscape) {
      //bottom: 2rem;
      margin-left: 0;
      text-align: center;
      width: 100%;
    }
  }
}

.c-start-button__label {
  @include ff('bold');
  @include type-scale(t1);
  
  @include breakpoint(orientation landscape) {
    float: left;

    @include breakpoint($tablet-small) {
      @include type-scale(t2);
    }

    @include breakpoint($tablet) {
      @include type-scale(t3);
    }

    @include breakpoint($tablet-landscape) {
      float: none;
    }
  }

  @include breakpoint(orientation portrait) {
    @include breakpoint($tablet-small) {
      //float: none;
      @include type-scale(t2);
    }

    @include breakpoint($tablet) {
      @include type-scale(t4);
    }
  }

  @include breakpoint($computer-large) {
    @include type-scale(t5);
  }
}

.c-start-button__button {
  background: {
    color: color('brand-primary');
    image: url('../img/caret-down-white.svg');
    position: 50% 56%;
    repeat: no-repeat;
    size: 60%;
  }
  border-radius: 100%;
  clear: none;
  display: block;
  height: 4.375rem;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0.25rem;
  position: relative;
  width: 4.375rem;
  z-index: 9999;

  @include breakpoint($mobile-landscape (orientation landscape)) {
    display: inline-block;
    height: 2rem;
    margin-left: .5rem;
    width: 2rem;
  }

  @include breakpoint($tablet-small (orientation landscape)) {
    height: 4.375rem;
    margin-left: 1rem;
    margin-top: -0.25rem;
    width: 4.375rem;
  }

  @include breakpoint($tablet (orientation portrait)) {
    height: 6.25rem;
    width: 6.25rem;
  }

  @include breakpoint($tablet-landscape) {
    @include breakpoint(orientation portrait) {
      height: 8.75rem;
      width: 8.75rem;
    }

    @include breakpoint(orientation landscape) {
      height: 5rem;
      width: 5rem;
    }
  }

  @include breakpoint($computer) {
    height: 6.25rem;
    width: 6.25rem;
  }

  @include breakpoint($computer-large) {
    height: 8.75rem;
    width: 8.75rem;
  }
}
